import React, { Suspense, lazy } from 'react';

// material-ui
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const GuestApp = lazy(() => import('./GuestApp'));

function App() {

  return (
    <div>
      <Suspense fallback={
        <Box
          sx={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>    
      }>
        <GuestApp />
    </Suspense>
    </div>
  );
}

export default App;